import { useQuery } from "@tanstack/react-query";

import useAuth from "@/hooks/useAuth";
import { getPaymentIntent } from "@/services/paymentIntent";

export const usePaymentIntent = (utmParams) => {
  const { auth } = useAuth();

  const { data, isLoading } = useQuery(
    ["paymentIntent", utmParams],
    () => getPaymentIntent(utmParams),
    {
      enabled: auth?.isAuth,
      refetchOnWindowFocus: false,
    }
  );

  return {
    paymentIntent: data?.paymentIntent,
    membershipInterval: data?.membershipPrice?.recurring?.interval,
    membershipPrice: data?.membershipPrice,
    sitter: data?.sitter,
    isLoading: isLoading,
  };
};
