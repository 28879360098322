import { useState, useEffect } from "react";

const useUtmParams = () => {
  const [utmParams, setUtmParams] = useState({
    utmSource: null,
    utmCampaign: null,
    utmMedium: null,
  });

  useEffect(() => {
    const checkUtmParams = () => {
      const utmSource = localStorage.getItem("utm_source")
        ? localStorage.getItem("utm_source").replace(/[^a-zA-Z0-9_-]/g, "")
        : null;
      const utmCampaign = localStorage.getItem("utm_campaign")
        ? localStorage.getItem("utm_campaign").replace(/[^a-zA-Z0-9_-]/g, "")
        : null;
      const utmMedium = localStorage.getItem("utm_medium")
        ? localStorage.getItem("utm_medium").replace(/[^a-zA-Z0-9_-]/g, "")
        : null;

      setUtmParams({
        utmSource,
        utmCampaign,
        utmMedium,
      });
    };

    checkUtmParams();

    // Listen for storage changes (in case other tabs update localStorage)
    window.addEventListener("storage", checkUtmParams);

    return () => window.removeEventListener("storage", checkUtmParams);
  }, []);

  return utmParams;
};

export default useUtmParams;
