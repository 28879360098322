import {
  AppShell,
  Header,
  Group,
  Container,
  Image,
  Anchor,
  Menu,
  UnstyledButton,
  Avatar,
} from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { RouterProvider } from "react-router-dom";
import {
  FaLifeRing,
  FaUserCircle,
  FaChevronDown,
  FaSignOutAlt,
  FaHome,
} from "react-icons/fa";

import router from "@/pages/router";
import useAuth from "@/hooks/useAuth";
import useGetUserData from "@/hooks/useGetUserData";
import logo from "@/assets/images/wyndy-horizontal-logo.svg";
import { ProgressBar } from "@/components/Common/ProgressBar";
import { ProgressBarProvider } from "@/contexts/ProgressBarContext";
import { AppContainer } from "@/components/Common/AppContainer";
import { useEffect } from "react";
import variables from "@/config/variables";
import useGTMPageView from "@/hooks/useGTMPageView";

function App() {
  useGTMPageView(router);
  const { auth, logout } = useAuth();
  const { data } = useGetUserData();
  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const utmSource = queryParams.get("utm_source");
    const utmCampaign = queryParams.get("utm_campaign");
    const utmMedium = queryParams.get("utm_medium");

    if (utmSource)
      localStorage.setItem(
        "utm_source",
        utmSource.replace(/[^a-zA-Z0-9_-]/g, "")
      );
    if (utmCampaign)
      localStorage.setItem(
        "utm_campaign",
        utmCampaign.replace(/[^a-zA-Z0-9_-]/g, "")
      );
    if (utmMedium)
      localStorage.setItem(
        "utm_medium",
        utmMedium.replace(/[^a-zA-Z0-9_-]/g, "")
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zipCode = queryParams.get("zip");

  useEffect(() => {
    if (zipCode) {
      window.localStorage.setItem(
        variables.localStorage.EXTERNAL_ZIP_CODE,
        zipCode
      );
    }
  }, [zipCode]);

  const isSubmitted =
    data?.status &&
    data?.status !== "started" &&
    data?.status !== "rejected" &&
    data?.status !== "auto_rejected" &&
    data?.qualified;

  const isAccepted = data?.status === "accepted";

  const renderHeaderMenu = () => (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <UnstyledButton variant="subtle">
          <Group spacing="xs">
            <Avatar src={data?.imageUrl} radius={4}>
              {data?.firstName &&
                data?.lastName &&
                `${data?.firstName} ${data?.lastName}`.match(/\b\w/g).join("")}
            </Avatar>
            <FaChevronDown size={12} color="gray" />
          </Group>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        {isAccepted && (
          <Menu.Item
            fw={600}
            component="a"
            href={`${process.env.REACT_APP_WYNDY_APP_URL}/sitters/account`}
            icon={<FaHome size={14} />}
          >
            My Account
          </Menu.Item>
        )}
        {isSubmitted && (
          <Menu.Item
            fw={600}
            component="a"
            href="/submission-profile"
            icon={<FaUserCircle size={14} />}
          >
            My Application
          </Menu.Item>
        )}
        <Menu.Item icon={<FaLifeRing size={14} />}>
          <Anchor fw={600} color="dark" href="mailto:support@wyndy.com">
            Contact Support
          </Anchor>
        </Menu.Item>
        <Menu.Item
          fw={600}
          component="a"
          color="red"
          icon={<FaSignOutAlt size={14} />}
          onClick={logout}
        >
          Log out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );

  return (
    <ProgressBarProvider>
      <NotificationsProvider>
        <AppShell
          padding="md"
          header={
            <Header height={70} py="xs">
              <Container pt={5} pb="sm" px={0} maw="100%">
                <Group sx={{ height: "100%" }} pl={15} pr={20} position="apart">
                  <Anchor href={"/"}>
                    <Image width={140} src={logo} />
                  </Anchor>
                  {!auth.isAuth ? (
                    window.location.pathname.indexOf("/login") === 0 ? (
                      <Anchor href={"/register"}>Sign up</Anchor>
                    ) : (
                      <Anchor href={"/login"}>Sign in</Anchor>
                    )
                  ) : (
                    renderHeaderMenu()
                  )}
                </Group>
              </Container>
              <ProgressBar />
            </Header>
          }
          styles={(theme) => ({
            main: {
              backgroundColor: theme.colors["background-white"][0],
              display: "flex",
              overflowX: "hidden",
            },
          })}
        >
          <AppContainer
            p="0"
            display="flex"
            style={{ flexDirection: "column" }}
          >
            <RouterProvider router={router} />
            {/*<Center mt="xl">© Wyndy, LLC. All rights reserved.</Center>*/}
          </AppContainer>
        </AppShell>
      </NotificationsProvider>
    </ProgressBarProvider>
  );
}

export default App;
