import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Text,
  List,
  LoadingOverlay,
  Alert,
  Space,
  Box,
  Flex,
  // Image,
} from "@mantine/core";
// import { showNotification } from "@mantine/notifications";

import { useApplicationFormContext } from "@/pages/Application/form-context";
import useForm from "@/pages/Application/hooks/useForm";
import { CheckoutForm } from "@/components/Stripe/CheckoutForm";
import { useProgressBar } from "@/contexts/ProgressBarContext";
import { usePaymentIntent } from "@/hooks/usePaymentIntent";
// import successImage from "@/assets/images/payment-success-48.svg";
import useUtmParams from "@/hooks/useUtmParams";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const stripeOptionsAppearance = {
  theme: "stripe",
  labels: "floating",
  variables: {
    fontFamily: " SofiaRegular,Arial",
    fontLineHeight: "1",
    borderRadius: "8px",
  },
  rules: {
    ".Block": {
      padding: "12px",
    },
    ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
      borderColor: "var(--colorPrimaryText)",
      boxShadow:
        "0 0 0 1.5px var(--colorPrimaryText), 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 7px rgba(18, 42, 66, 0.04)",
    },
    ".Input": {
      padding: "5px 12px",
      fontSize: "14px",
    },
    ".Label": {
      color: "#77808e",
      fontSize: "14px",
    },
  },
};

const SubscriptionPayment = () => {
  useProgressBar(8);
  const { goToNextStep } = useForm();
  const utmParams = useUtmParams();

  const {
    paymentIntent,
    membershipInterval,
    membershipPrice,
    sitter,
    isLoading: isPaymentIntentLoading,
  } = usePaymentIntent(utmParams);

  const form = useApplicationFormContext();
  const hasActivePromo = sitter?.activePromo;

  const [loading, setLoading] = useState(true);

  const membershipPriceAmount = membershipPrice?.unitAmount
    ? (membershipPrice?.unitAmount / 100).toFixed()
    : "...";
  const clientSecret = paymentIntent?.clientSecret;
  const amount = paymentIntent?.amount;
  const formattedAmount = amount ? (amount / 100).toFixed() : "...";
  const monthlyPrice = amount ? (amount / 12 / 100).toFixed(2) : "...";
  const currentMembershipInterval =
    membershipInterval ?? form.values.membershipInterval;
  const formattedInterval =
    currentMembershipInterval === "month"
      ? "mo"
      : currentMembershipInterval === "year"
      ? "yr"
      : membershipInterval;

  const stripeOptions = {
    clientSecret: clientSecret,
    appearance: stripeOptionsAppearance,
    layout: {
      type: "tabs",
      defaultCollapsed: false,
    },
    locale: "en",
  };

  useEffect(() => {
    if (window.location.search.indexOf("payment_intent") !== -1) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    // showNotification({
    //   autoClose: 50000,
    //   icon: <Image width={48} src={successImage} />,
    //   title: "Payment successful",
    //   message: "Enter your details to continue applying.",
    //   styles: (theme) => ({
    //     root: {
    //       background: "rgba(43, 163, 166, 0.8)",
    //       border: "1px solid #2BA3A6",
    //     },
    //     title: { color: theme.white },
    //     description: { color: theme.white },
    //     closeButton: {
    //       color: theme.white,
    //     },
    //   }),
    // });
    goToNextStep();
  };

  return (
    <div>
      <LoadingOverlay visible={isPaymentIntentLoading || loading} />

      {hasActivePromo ? (
        <>
          <Text mt="sm">
            <Text
              component="span"
              color="strong-cta.0"
              size="xl"
              fw={1000}
              style={{ fontSize: "42px" }}
            >
              First month, only ${formattedAmount}! 🎉
            </Text>
          </Text>

          <Text mt="sm" fw={500}>
            Your membership includes:
          </Text>
          <List mt="sm">
            <List.Item>
              <Text component="span" fw={700}>
                Background checks
              </Text>
            </List.Item>
            <List.Item>
              <Text component="span" fw={700}>
                College student verification
              </Text>
            </List.Item>
            <List.Item>
              <Text component="span" fw={700}>
                Occupational accident insurance
              </Text>{" "}
              that protects you on every Wyndy job.
            </List.Item>
            <List.Item>
              <Text component="span" fw={700}>
                Fraud protection
              </Text>{" "}
              to guarantee you are paid for every Wyndy job.
            </List.Item>
            <List.Item>
              <Text component="span" fw={700}>
                Renews at ${membershipPriceAmount}/{formattedInterval}
              </Text>{" "}
              Cancel anytime
            </List.Item>
          </List>
        </>
      ) : (
        <>
          <Text size="xl" weight="500" fw="bold">
            Trust & Safety Fee
          </Text>

          <Text mt="sm">
            <Text
              component="span"
              color="strong-cta.0"
              size="xl"
              fw={700}
              style={{ fontSize: "31px" }}
            >
              ${formattedAmount}
            </Text>{" "}
            <Text component="span" color="strong-cta.0" fw={500}>
              / {formattedInterval}
            </Text>
            {membershipInterval === "year" && (
              <Text component="span" fw={500}>
                {" "}
                (only ${monthlyPrice} a month)
              </Text>
            )}
          </Text>

          <Text mt="sm" fw={500}>
            This covers:
          </Text>
          <List mt="sm">
            <List.Item>
              <Text component="span" fw={700}>
                Background checks
              </Text>
            </List.Item>
            <List.Item>
              <Text component="span" fw={700}>
                College student verification
              </Text>
            </List.Item>
            <List.Item>
              <Text component="span" fw={700}>
                Occupational accident insurance
              </Text>{" "}
              that protects you on every Wyndy job.
            </List.Item>
            <List.Item>
              <Text component="span" fw={700}>
                Fraud protection
              </Text>{" "}
              to guarantee you are paid for every Wyndy job.
            </List.Item>
          </List>
        </>
      )}
      <Alert
        mt="xl"
        mb="sm"
        style={{
          backgroundColor: "#F7F7F7",
          border: "1px solid #DEDEDE",
          borderRadius: 20,
        }}
      >
        <Flex align="center" direction="row">
          <Box>
            <Text fz="sm" fw={500} align="center">
              You won't be charged until your application is approved. Cancel
              anytime after that.
            </Text>
          </Box>
        </Flex>
      </Alert>

      <Space h="sm" />

      {clientSecret && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <CheckoutForm
            clientSecret={clientSecret}
            onSubmit={handleSubmit}
            onReady={() => {
              setLoading(false);
            }}
            onChange={() => {
              form.clearErrors();
            }}
          />
        </Elements>
      )}
    </div>
  );
};

export default SubscriptionPayment;
