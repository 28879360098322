import { useEffect, useRef } from "react";

const useGTMPageView = (router) => {
  const prevLocationRef = useRef({});
  const timeoutIdRef = useRef(null);

  useEffect(() => {
    // timeoutIdRef.current = setTimeout(() => {
    //   sendPageView(Object.assign({}, window.location));
    // }, 500);

    const unsubscribe = router.subscribe(({ location: newLocation }) => {
      clearTimeout(timeoutIdRef.current);
      timeoutIdRef.current = setTimeout(() => {
        sendPageView(newLocation);
      }, 500);
    });

    return () => () => {
      unsubscribe();
      clearTimeout(timeoutIdRef.current);
    };
  }, [router]);

  const sendPageView = (newLocation) => {
    if (
      newLocation.pathname !== prevLocationRef.current.pathname ||
      newLocation.search !== prevLocationRef.current.search
    ) {
      prevLocationRef.current = newLocation;

      if (window.dataLayer) {
        window.dataLayer.push({
          event: "pageview",
          page: newLocation.pathname + newLocation.search,
          pageTitle: document.title,
        });
      }
    }
  };
};

export default useGTMPageView;
